import { Comentario } from './comentario';
import { Arquivo } from './arquivo';

export class Ocorrencia {
    id: number;
    codigo: string;
    status: string;
    dataCriacao: Date;
    dataUltimaAlteracao: Date;
    dataFechamento: Date;
    assunto: string;
    anonimo: boolean;
    emailAnonimo: string;
    nomeDenunciante: string;
    emailDenunciante: string;
    cpfDenunciante: string;
    telefoneDenunciante: string;
    relacaoEmpresaDenunciante: string;
    cargoAreaDenunciante: string;
    relacaoEmpresaDenunciado: string;
    identificacaoDenunciado: boolean;
    dataOcorrencia: string;
    recorrenciaEvento: boolean;
    tempoOcorrencia: string;
    codigoRelatoAnterior: string;
    origemOcorrencia: string;
    valorOcorrencia: string;
    sugestaoSolucao: string;
    categoriaRelato: string;
    descricaoEvidencias: string;
    arquivos: Arquivo[];
    comentarios: Comentario[];
}

