import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GenericService } from '../core/generic.service';
import { Observable } from 'rxjs';
import { Usuario } from '../model/usuario';
import { Ocorrencia } from '../model/ocorrencia';

@Injectable({
  providedIn: 'root'
})
export class OcorrenciaService extends GenericService {

  private relativePath: string = 'api/occurrences';

  constructor(http: HttpClient) {
    super(http);
  }

  nova(ocorrencia: Ocorrencia) : Observable<any> {
    return this.http.post(this.url + this.relativePath + "/add", ocorrencia);
  }

  buscarPorId(id: number) : Observable<any> {
    return this.http.get(this.url + this.relativePath + "/find/" + id);
  }

  buscarPorCodigo(codigo: string) : Observable<any> {
    return this.http.get(this.url + this.relativePath + "/find/" + codigo + "/codigo");
  }

  atualizar(ocorrencia: Ocorrencia) : Observable<any> {
    return this.http.put(this.url + this.relativePath + "/edit", ocorrencia);
  }

  buscarOcorrencias(status: string) : Observable<any> {
    return this.http.get(this.url + this.relativePath + "/find/status/" + status);
  }

}
