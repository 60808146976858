import { Usuario } from './usuario';
import { Ocorrencia } from './ocorrencia';
import { Arquivo } from './arquivo';

export class Comentario {
  id: number;
  conteudo: string;
  dataCriacao: Date;
  dataUltimaAlteracao: Date;
  usuario: Usuario;
  ocorrencia: Ocorrencia;
  arquivos: Arquivo[];
}