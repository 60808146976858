import { Component, OnInit } from '@angular/core';
import { SecurityService, USUARIO_SEG } from 'src/app/security/_services/security.service';
import { Router } from '@angular/router';
import { UsuarioService } from 'src/app/service/usuario.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  renderButton: boolean = false;

  constructor(
    private securityService: SecurityService,
    private usuarioService: UsuarioService,
    private router: Router
  ) { }

  ngOnInit() {
    // this.configMenuHeader();
  }

  renderMenu() {
    if (this.router.url === '/login' || this.router.url === '/cadastro') {
      return false;
    } else {
      return true;
    }
  }

  renderHeader() {
    if (this.router.url === '/login') {
      return false;
    } else {
      return true;
    }
  }

  renderSair() {
    if (this.securityService.authenticationPresent()) {
      return true;
    }
  }

  configMenuHeader() : boolean { // todo
    // Abra sua Empresa
      // -> se o usuário não tiver empresa associada
      // -> se o usuário ja tiver empresa associada com cadastro incompleto

    // Acompanhar Abertura
      // -> se o usuário ja tiver empresa associada com cadastro completo

    console.log(window.localStorage.getItem(USUARIO_SEG));

    return true;
  }

  openPage(route) {
    this.router.navigate([route]);
  }

  sair() {
    this.securityService.clearSessionData();
    this.router.navigate(['login']);
  }

}
