import { UsuarioSeg } from './usuario-seg';
import { Empresa } from './empresa';
import { ProcuradoriaEtapa } from './procuradoria-etapa';

export class ProcuradoriaArquivo {
  id: number;
  nome: string;
  formato: string;
  descricao: string;
  tipo: string; // todo enum tipos
  usuario: UsuarioSeg;
  empresa: Empresa;
  etapa: ProcuradoriaEtapa;
  keyAwsArquivo: string;
  dataEnvio: Date;
  dataModificacao: Date;
}
