import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GenericService } from '../core/generic.service';
import { Observable } from 'rxjs';
import { Usuario } from '../model/usuario';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService extends GenericService {

  private relativePath: string = 'api/users';

  constructor(http: HttpClient) {
    super(http);
  }

  novo(usuario: Usuario) : Observable<any> {
    return this.http.post(this.url + this.relativePath + "/add", usuario);
  }

  buscarPorId(id: number) : Observable<any> {
    return this.http.get(this.url + this.relativePath + "/find/" + id);
  }

  buscarPorEmail(email: string) : Observable<any> {
    return this.http.get(this.url + this.relativePath + "/find/email/" + email);
  }

}
