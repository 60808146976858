import { NgModule } from '@angular/core';
import { MatButtonModule, MatCheckboxModule, MatFormFieldModule, MatInputModule, MatTableModule, MatAutocompleteModule, MatIconModule, MatNativeDateModule, MatPaginatorModule, MatOptionModule, MatSelectModule, MatStepperModule, MatRadioModule, MatCardModule, MatDatepickerModule, MatListModule, MatSidenavModule, MatGridListModule, MatExpansionModule, MatDialogModule } from '@angular/material';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
    imports: [
      MatAutocompleteModule,
      MatButtonModule,
      MatCardModule,
      MatCheckboxModule,
      MatFormFieldModule,
      MatOptionModule,
      MatSelectModule,
      MatInputModule,
      MatTableModule,
      ReactiveFormsModule,
      MatIconModule,
      MatNativeDateModule,
      MatPaginatorModule,
      MatStepperModule,
      MatRadioModule,
      MatDatepickerModule,
      MatListModule,
      MatSidenavModule,
      MatGridListModule,
      MatExpansionModule,
      MatDialogModule
    ],
    exports: [
      MatAutocompleteModule,
      MatButtonModule,
      MatCardModule,
      MatCheckboxModule,
      MatFormFieldModule,
      MatOptionModule,
      MatSelectModule,
      MatInputModule,
      MatTableModule,
      ReactiveFormsModule,
      MatIconModule,
      MatNativeDateModule,
      MatPaginatorModule,
      MatStepperModule,
      MatRadioModule,
      MatDatepickerModule,
      MatListModule,
      MatSidenavModule,
      MatGridListModule,
      MatExpansionModule,
      MatDialogModule
    ]
})
export class MaterialModule { }