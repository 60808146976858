import { Usuario } from './usuario';
import { Ocorrencia } from './ocorrencia';

export class Arquivo {
  id: number;
  nome: string;
  formato: string;
  descricao: string;
  keyAws: string;
  dataEnvio: Date;
  dataInativacao: Date;
  status: boolean;
  usuario: Usuario;
  ocorrencia: Ocorrencia;
}