import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UsuarioService } from 'src/app/service/usuario.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Usuario } from 'src/app/model/usuario';
import { SecurityService } from 'src/app/security/_services/security.service';

@Component({
  selector: 'app-cadastro',
  templateUrl: './cadastro.component.html',
  styleUrls: ['./cadastro.component.css']
})
export class CadastroComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;

  cadastroFormGroup: FormGroup;
  cadastroBemSucedido: boolean = false;

  constructor(    
    private formBuilder: FormBuilder,
    private usuarioService: UsuarioService,
    private securityService: SecurityService,
    private router: Router,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    if (this.securityService.authenticationPresent()) {
      this.router.navigate(['']);
    }
    this.initForm();
  }

  initForm() {
    this.cadastroFormGroup = this.formBuilder.group({
      nome: ['', Validators.required],
      sobrenome: ['', Validators.required],
      dataNascimento: ['', Validators.required],
      cpf: ['', Validators.required],
      celular: ['', Validators.required],
      email: ['', Validators.required],
      senha: ['', Validators.required],
      confirmacaoSenha: ['', Validators.required]
    });
  }

  bemSucedido() : boolean {
    return this.cadastroBemSucedido;
  }

  cadastrar() {
    if (this.cadastroFormGroup.invalid) {
      this.toastr.warning("Preencha todos os campos");
      return;
    }

    const senha = this.cadastroFormGroup.get('senha').value;
    const confirmacaoSenha = this.cadastroFormGroup.get('confirmacaoSenha').value;

    if (this.compararSenhas(senha, confirmacaoSenha)) {
      this.toastr.warning("As senhas não são iguais");
    }

    const nome = this.cadastroFormGroup.get('nome').value;
    const sobrenome = this.cadastroFormGroup.get('sobrenome').value;
    const dataNascimento = this.cadastroFormGroup.get('dataNascimento').value;
    const cpf = this.cadastroFormGroup.get('cpf').value;
    const celular = this.cadastroFormGroup.get('celular').value;
    const email = this.cadastroFormGroup.get('email').value;

    const usuario = new Usuario;
    usuario.id = null;
    usuario.nome = nome;
    usuario.sobrenome = sobrenome;
    // usuario.dataNascimento = dataNascimento;
    // usuario.cpf = cpf;
    usuario.celular = celular;
    usuario.email = email;
    usuario.senha = senha;
    // usuario.cargo = null;

    console.log(usuario);

    this.blockUI.start("Seu cadastro está sendo realizado...");
    this.usuarioService.novo(usuario).subscribe(result => {
      this.cadastroBemSucedido = true;
      this.blockUI.stop();
    }, error => {
      let status = error.status;
      if (status === 0 || status === 500 || status === 501 || status === 502 || status === 503 || status === 504 || status === 505) {        
        this.toastr.warning("Serviço temporariamente indisponível.");
      } else {
        this.toastr.warning("Erro ao realizar cadastro.");
      }
      this.cadastroBemSucedido = false;
      this.blockUI.stop();
    });

  }

  telaLogin() {
    this.router.navigate(['login']);
  }

  compararSenhas(senha, confirmacaoSenha) : boolean {
    if (senha === confirmacaoSenha) {
      return false;
    }
    return true;
  }

  limpar() {
    this.cadastroFormGroup.get('nome').setValue("");
    this.cadastroFormGroup.get('sobrenome').setValue("");
    this.cadastroFormGroup.get('dataNascimento').setValue("");
    this.cadastroFormGroup.get('cpf').setValue("");
    this.cadastroFormGroup.get('celular').setValue("");
    this.cadastroFormGroup.get('email').setValue("");
    this.cadastroFormGroup.get('confirmacaoSenha').setValue("");
  }

}
