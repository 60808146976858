import { Injectable } from '@angular/core';
import { GenericService } from '../core/generic.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ProcuradoriaEtapa } from '../model/procuradoria-etapa';

@Injectable({
  providedIn: 'root'
})
export class ProcuradoriaEtapaService extends GenericService {

  private relativePath = 'api/procuradoria/etapas';

  constructor(http: HttpClient) { 
    super(http);
  }

  buscarPorEmpresa(idEmpresa: number) : Observable<any> {
    return this.http.get(this.url + this.relativePath + "/find/empresa/" + idEmpresa);
  }

  atualizarStatusEtapa(etapa: ProcuradoriaEtapa) : Observable<any> {
    return this.http.put(this.url + this.relativePath + "/update-status", etapa);
  }
  

}
