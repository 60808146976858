import { Component, OnInit, Inject } from '@angular/core';
import { EmpresaService } from 'src/app/service/empresa.service';
import { SecurityService, USUARIO_SEG } from 'src/app/security/_services/security.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { EmpresaProcuradoria } from 'src/app/model/empresa-procuradoria';
import { MatTableDataSource, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ProcuradoriaEtapa } from 'src/app/model/procuradoria-etapa';
import { ProcuradoriaEtapaService } from 'src/app/service/procuradoria-etapa.service';
import { ProcuradoriaComentarioService } from 'src/app/service/procuradoria-comentario.service';
import { ProcuradoriaComentario } from 'src/app/model/procuradoria-comentario';
import { UsuarioSeg } from 'src/app/model/usuario-seg';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ProcuradoriaArquivoService } from 'src/app/service/procuradoria-arquivo.service';
import { ProcuradoriaArquivo } from 'src/app/model/procuradoria-arquivo';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Empresa } from 'src/app/model/empresa';
import { saveAs } from 'file-saver';
import { Socio } from 'src/app/model/socio';
import { Ocorrencia } from 'src/app/model/ocorrencia';
import { Usuario } from 'src/app/model/usuario';
import { Comentario } from 'src/app/model/comentario';
import { ComentarioService } from 'src/app/service/comentario.service';

@Component({
  selector: 'app-acompanhamento-empresa',
  templateUrl: './acompanhamento.component.html',
  styleUrls: ['./acompanhamento.component.css']
})
export class AcompanhamentoEmpresaComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;

  ocorrencia: Ocorrencia;
  empresa: EmpresaProcuradoria;
  renderPagina: boolean = false;
  panelOpenStateEmpresa: boolean = false;
  panelOpenStateSocio: boolean = false;
  dataSource = new MatTableDataSource<ProcuradoriaEtapa>();
  displayedColumns: string[] = ['descricao', 'status', 'acoes'];
  
  etapas: ProcuradoriaEtapa[] = [];
  procuradoriaEtapa = new ProcuradoriaEtapa();

  novoComentario = '';
  placeHolderComentario: string = "Adicionar um comentário...";
  checked = true;

  arquivo: File = null;

  arquivosFormGroup: FormGroup;

  // foto = "https://images-na.ssl-images-amazon.com/images/I/614VmsFwoNL._AC_SL1134_.jpg";
  foto = "https://material.angular.io/assets/img/examples/shiba1.jpg";

  constructor(
    private empresaService: EmpresaService,
    private securityService: SecurityService,
    private procuradoriaEtapaService: ProcuradoriaEtapaService,
    private procuradoriaComentarioService: ProcuradoriaComentarioService,
    private procuradoriaArquivoService: ProcuradoriaArquivoService,
    private comentarioService: ComentarioService,
    private router: Router,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.ocorrencia = JSON.parse(window.localStorage.getItem('detalhe_ocorrencia'));
    console.log(this.ocorrencia);
    
    this.buscarEtapas();
    this.initForm();
  }

  initForm() {
    this.arquivosFormGroup = this.formBuilder.group({
      arquivo: null
    });
  }

  voltar() {
    this.router.navigate(['painel/denuncias']);
  }

  setProfileImage(perfilUsuarioSeg: string) {
    if (perfilUsuarioSeg != null && perfilUsuarioSeg != undefined) {
      return `url(${perfilUsuarioSeg})`; // todo pegar a foto do perfil
    } else {
      return `url(${'assets/images/user.png'})`;
    }
  }

  buscarEtapas() {
    this.etapas = this.buildEtapas();
    // if (this.empresa != null && this.empresa != undefined) {
    //   this.blockUI.start();
    //   this.procuradoriaEtapaService.buscarPorEmpresa(this.empresa.id).subscribe(etapas => {
    //     this.etapas = etapas;
    //     this.blockUI.stop();
    //   }, error => {
    //     let status = error.status;
    //     if (status === 0 || status === 500 || status === 501 || status === 502 || status === 503 || status === 504 || status === 505) {        
    //       this.toastr.warning("Serviço temporariamente indisponível.");
    //     } else {
    //       this.toastr.warning("Erro ao realizar busca.");
    //     }
    //     this.blockUI.stop();
    //   });
    // } else {
      // todo tratar erro de empresa inexistente
      // redirecionar para tela inicial de procuradoria
    // }
  }

  enviarComentario(ocorrencia: number) {
    // verificar se o campo de comentário não está vazio
    if (this.novoComentario == "" || this.novoComentario == null || this.novoComentario == undefined) {
      return;
    }
    
    let comentario = new Comentario();
    comentario.ocorrencia = this.ocorrencia;
    comentario.conteudo = this.novoComentario;

    this.comentarioService.novo(comentario).subscribe(data => {
      this.ocorrencia.comentarios = data;
      // this.atualizarComentariosEtapa(ocorrencia);
    });
    
    this.novoComentario = "";
  }

  atualizarComentariosEtapa(id: number) {
    this.comentarioService.buscarPorOcorrencia(id).subscribe(comentarios => {
      this.etapas.map(etapa => {
        if (etapa.id == id) {
          etapa.comentarios = comentarios;
        }
      });
    });
  }

  editarComentario(procuradoriaComentario: ProcuradoriaComentario) {
    let comentario = procuradoriaComentario.conteudo;
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      width: '500px',
      data: { comentario: comentario }
    });

    dialogRef.afterClosed().subscribe(comentarioAtualizado => {
      procuradoriaComentario.conteudo = comentarioAtualizado;
      this.procuradoriaComentarioService.atualizar(procuradoriaComentario).subscribe(comentario => {
        this.atualizarComentariosEtapa(procuradoriaComentario.idEtapaProcuradoria);
      });
    });
  }

  removerComentario(procuradoriaComentario: ProcuradoriaComentario) {
    this.procuradoriaComentarioService.remover(procuradoriaComentario.id).subscribe(data => {
      this.atualizarComentariosEtapa(procuradoriaComentario.idEtapaProcuradoria);
    });
  }

  atualizarStatusEtapa(event, etapa: ProcuradoriaEtapa) {
    event.stopPropagation();
    
    // console.log("etapa", etapa);
    if (etapa !== null && etapa !== undefined) {
      this.procuradoriaEtapaService.atualizarStatusEtapa(etapa).subscribe(etapaAtualizada => {
        etapa = etapaAtualizada;
        // console.log("etapaAtualizada", etapaAtualizada);
      }, error => {
        let status = error.status;
        if (status === 0 || status === 500 || status === 501 || status === 502 || status === 503 || status === 504 || status === 505) {
          this.toastr.warning("Serviço temporariamente indisponível.");
        } else {
          this.toastr.warning(`Erro ao atualizar etapa ${etapa.etapa}.`);
        }
        etapa.status = !etapa.status;
      });
    }
  }

  checkStatus(ocorrencia: Ocorrencia) {
    console.log("Status", ocorrencia.status);
    if (ocorrencia.status) {
      return  `Finalizada em ${this.formatarData(ocorrencia.dataFechamento)}`;
    } else {
      return false;
    }
  }  

  checkPlaceHolder() {
    if (this.placeHolderComentario != "") {
      this.placeHolderComentario = "";
    } else if (this.placeHolderComentario == "" && this.novoComentario == "") {
      this.placeHolderComentario = "Adicionar um comentário...";
    }
    return;
  }

  formatarDataNascimento(data: Date) {
    data = new Date(data[0], data[1], data[2], data[3], data[4]);
    let dataString: string = `${data.getDate()}/${data.getMonth()}/${data.getFullYear()}`;
    return dataString;
  }

  formatarData(data: Date) {
    data = new Date(data[0], data[1] - 1, data[2], data[3], data[4]);
    let dataString: string = `${this.formatarMenorQueDez(data.getDate())}/${this.formatarMenorQueDez(data.getMonth())}/${data.getFullYear()} ${this.formatarMenorQueDez(data.getHours() - 1)}:${this.formatarMenorQueDez(data.getMinutes())}`;
    return dataString;
  }

  formatarDataAlteracao(dataCricao: Date, dataAlteracao) {
    if (dataAlteracao != null && dataAlteracao != undefined && dataCricao != dataAlteracao) {
      dataAlteracao = new Date(dataAlteracao);
      let dataString: string = `. Atualizado em ${this.formatarMenorQueDez(dataAlteracao.getDate())}/${this.formatarMenorQueDez(dataAlteracao.getMonth())}/${dataAlteracao.getFullYear()} ${this.formatarMenorQueDez(dataAlteracao.getHours() - 1)}:${this.formatarMenorQueDez(dataAlteracao.getMinutes())}`;
      return dataString;
    }
    return;
  }

  formatarMenorQueDez(numero: number) {
    return numero < 10 ?  '0' + numero : numero;
  }

  ultimoComentario(comentarios: ProcuradoriaComentario[]) {
    if (comentarios != undefined && comentarios.length !== 0) {
      var comentarioMaisRecente: ProcuradoriaComentario;
      comentarioMaisRecente = comentarios.reduce(
        (comentario1, comentario2) => {
          return comentario1.dataCriacao > comentario2.dataCriacao ? comentario1 : comentario2;
        }
      );
      return comentarioMaisRecente.conteudo;
    } else {
      return;
    }
  }

  listarDocumentos(event, etapa: ProcuradoriaEtapa) {
    event.stopPropagation();
    // console.log(etapa.arquivos.length);

    const dialogRef = this.dialog.open(AnexarArquivoDialog, {
      width: '1000px',
      // height: '100%',
      data: { etapa: etapa }
    });

    dialogRef.afterClosed().subscribe(retorno => {});
  }

  makeId(etapa) : string {
    return `file${etapa}`
  }

  downloadArquivo(socio: Socio, tipoDocumento: string) {
    // todo download do documento correspondente
  }

  buildEtapas() : ProcuradoriaEtapa[] {
    let arquivos: ProcuradoriaArquivo[] = [];
    let comentarios: ProcuradoriaComentario[] = [];
    let comentario1 = new ProcuradoriaComentario();
    let comentario2 = new ProcuradoriaComentario();

    comentario1.id = 4
    comentario1.usuario = new UsuarioSeg();
    comentario1.idEtapaProcuradoria = 5;
    comentario1.conteudo = "Comentário sobre a ocorrência";
    comentario1.dataCriacao = new Date();
    comentario1.dataUltimaAlteracao = new Date();
    
    comentario2 = comentario1;

    comentarios.push(comentario1);
    comentarios.push(comentario2);


    let etapa1 = new ProcuradoriaEtapa();
    let etapa2 = new ProcuradoriaEtapa();

    etapa1.id = 1;
    etapa1.idEmpresa = 2;
    etapa1.dataInicio = new Date();
    etapa1.dataConclusao = new Date();
    etapa1.etapa = "ETAPA 1";
    etapa1.descricao = "Descrição etapa 1";
    etapa1.orgao = "Teste orgão"
    etapa1.status = true;
    etapa1.comentarios = comentarios;
    etapa1.arquivos = arquivos;

    etapa2.id = 3;
    etapa2.idEmpresa = 4;
    etapa2.dataInicio = new Date();
    etapa2.dataConclusao = new Date();
    etapa2.etapa = "ETAPA 2";
    etapa2.descricao = "Descrição etapa 2";
    etapa2.orgao = "Teste orgão"
    etapa2.status = true;
    etapa2.comentarios = comentarios;
    etapa2.arquivos = arquivos;

    this.etapas.push(etapa1);
    this.etapas.push(etapa2);

    return this.etapas;
  }

}


@Component({
  selector: 'dialog-overview-example-dialog',
  templateUrl: 'dialog-editar-comentario.html',
  styleUrls: ['./acompanhamento.component.css']
})
export class DialogOverviewExampleDialog {

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
    @Inject(MAT_DIALOG_DATA) public data: { comentario: string }
  ) {}

  onNoClick() : void {
    this.dialogRef.close();
  }
}


@Component({
  selector: 'anexar-arquivo-dialog',
  templateUrl: 'anexar-arquivo-dialog.html',
  styleUrls: ['./acompanhamento.component.css']
})
export class AnexarArquivoDialog {
  @BlockUI() blockUI: NgBlockUI;

  existeArquivos: boolean;
  arquivo: File = null;

  constructor(
    public dialogRef: MatDialogRef<AnexarArquivoDialog>,
    @Inject(MAT_DIALOG_DATA) public data: { etapa: ProcuradoriaEtapa },
    private procuradoriaArquivoService: ProcuradoriaArquivoService,
    private toastr: ToastrService
  ) {}

  onNoClick() : void {
    // console.log("data", this.data);
    this.dialogRef.close();
  }

  semArquivos(): boolean {
    if (this.data.etapa.arquivos.length != 0) {
      this.existeArquivos = true;
    } else {
      this.existeArquivos = false;
    }
    return this.existeArquivos;
  }

  formatarData(data: Date) {
    data = new Date(data);
    let dataString: string = `${this.formatarMenorQueDez(data.getDate())}/${this.formatarMenorQueDez(data.getMonth() + 1)}/${data.getFullYear()} ${this.formatarMenorQueDez(data.getHours() - 1)}:${this.formatarMenorQueDez(data.getMinutes())}`;
    return dataString;
  }

  formatarMenorQueDez(numero: number) {
    return numero < 10 ?  '0' + numero : numero;
  }

  inputArquivo(files: FileList) {
    this.arquivo = files.item(0);
    // console.log(this.arquivo.name);
    this.uploadArquivo(this.data.etapa.idEmpresa, this.data.etapa.id);
    this.arquivo = null;
  }

  uploadArquivo(idEmpresa, idEtapa) {

    if ( this.arquivo.size > 10485760) {
      this.toastr.warning("O arquivo é maior que 10MB");
      return;
    }

    let usuario = JSON.parse(window.localStorage.getItem(USUARIO_SEG));
    let usuarioSeg = new UsuarioSeg();
    let empresa = new Empresa();
    let etapa = new ProcuradoriaEtapa();
    usuarioSeg.idUsuarioSeg = usuario.id;
    empresa.id = idEmpresa;
    etapa.id = idEtapa;

    let procuradoriaArquivo: ProcuradoriaArquivo = new ProcuradoriaArquivo();
    procuradoriaArquivo.descricao = "-";
    procuradoriaArquivo.tipo = "-"; // todo enum tipo
    procuradoriaArquivo.usuario = usuarioSeg;
    procuradoriaArquivo.empresa = empresa;
    procuradoriaArquivo.etapa = etapa;

    this.blockUI.start("Enviando arquivo...");
    if (this.arquivo) {
      this.procuradoriaArquivoService.upload(procuradoriaArquivo, this.arquivo).subscribe(data =>{
        // console.log("arquivo-retorno", data);
        this.atualizarArquivos(this.data.etapa.id);
        this.blockUI.stop();
      }, error => {
        let status = error.status;
        if (status === 0 || status === 500 || status === 501 || status === 502 || status === 503 || status === 504 || status === 505) {        
          this.toastr.warning("Serviço temporariamente indisponível.");
        } else {
          this.toastr.warning("Erro ao realizar upload de arquivo.");
        }
        this.blockUI.stop();
      });
    } else {
      console.log("Não há arquivo a ser enviado.");
    }
  }

  atualizarArquivos(idEtapa: number) {
    let arquivos: ProcuradoriaArquivo[];
    this.procuradoriaArquivoService.buscarArquivosPorEtapa(idEtapa).subscribe(data => {
      arquivos = data;
      this.data.etapa.arquivos = arquivos;
    }); // todo error
  }

  downloadArquivo(procuradoriaArquivo: ProcuradoriaArquivo) {
    this.procuradoriaArquivoService.download(procuradoriaArquivo.id).subscribe(responseArquivo => {
      const arquivo = new Blob([responseArquivo]);
      saveAs(arquivo, `${procuradoriaArquivo.nome}`);
    }); // todo error
  }

  removerArquivo(procuradoriaArquivo: ProcuradoriaArquivo) {
    let usuario: UsuarioSeg = JSON.parse(window.localStorage.getItem(USUARIO_SEG)); // testar isso
    let usuarioSeg = new UsuarioSeg();
    usuarioSeg.idUsuarioSeg = usuario.id;

    procuradoriaArquivo.usuario = usuarioSeg;

    this.blockUI.start("Removendo arquivo...");
    
    this.procuradoriaArquivoService.atualizarStatus(procuradoriaArquivo).subscribe(data => {
      this.atualizarArquivos(procuradoriaArquivo.etapa.id);
      this.blockUI.stop();
    }); // todo error
  }

}