import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css', '../../app.component.css']
})
export class FooterComponent implements OnInit {

  version: string = environment.VERSION;
  anoAtual: string = environment.ANO;

  constructor(
    public router: Router,
  ) { }

  ngOnInit() {
  }

  fixBottom() {
    // console.log("url footer", this.router.url);
    if (this.router.url === '/login' || this.router.url === '/' || '/painel') {
      return 'bottom-fixed';
    }
  }

}
