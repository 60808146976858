import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './template/header/header.component';
import { FooterComponent } from './template/footer/footer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';
import { LoginComponent } from './pages/login/login.component';
import { BlockUIModule } from 'ng-block-ui';
import { SecurityService } from './security/_services/security.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthenticationInterceptor } from './security/_guards/authentication.interceptor';
import { FormsModule } from '@angular/forms';
import { HomeComponent } from './pages/home/home.component';
import { CnaeService } from './service/cnae.service';
import { FileUploadService } from './service/file-upload.service';
import { ToastrModule } from 'ngx-toastr';
import { PlanosComponent } from './components/planos/planos.component';
import { CadastroComponent } from './pages/cadastro/cadastro.component';
import { PainelComponent } from './pages/painel/painel.component';
import { AbertasComponent } from './pages/painel/ocorrencias/abertas/abertas.component';
import { AcompanhamentoEmpresaComponent, DialogOverviewExampleDialog, AnexarArquivoDialog } from './pages/painel/ocorrencias/acompanhamento/acompanhamento.component';
import { ProcuradoriaEtapaService } from './service/procuradoria-etapa.service';
import { ArquivoService } from './service/arquivo.service';
import { ComentarioService } from './service/comentario.service';
import { BuscarOcorrenciaComponent } from './pages/acompanhamento/buscar/buscar-ocorrencia.component';
import { OcorrenciaService } from './service/ocorrencia.service';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    LoginComponent,
    CadastroComponent,
    PainelComponent,
    AbertasComponent,
    AcompanhamentoEmpresaComponent,
    PlanosComponent,
    DialogOverviewExampleDialog,
    AnexarArquivoDialog,
    BuscarOcorrenciaComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    HttpClientModule,
    FormsModule,
    BlockUIModule.forRoot({
      delayStart: 500,
      delayStop: 500
    }),
    ToastrModule.forRoot({
      //positionClass: 'toast-bottom-right',
      preventDuplicates: true,
      progressBar: true
    })
  ],
  providers: [
    SecurityService,
    CnaeService,
    ArquivoService,
    ComentarioService,
    OcorrenciaService,
    FileUploadService,
    ProcuradoriaEtapaService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'pt-BR' },
  ],
  entryComponents:[
    DialogOverviewExampleDialog,
    AnexarArquivoDialog
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
