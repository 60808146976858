import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { SecurityService, PERFIS } from 'src/app/security/_services/security.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Ocorrencia } from 'src/app/model/ocorrencia';
import { OcorrenciaService } from 'src/app/service/ocorrencia.service';

@Component({
  selector: 'app-abertas',
  templateUrl: './abertas.component.html',
  styleUrls: ['./abertas.component.css']
})
export class AbertasComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @BlockUI() blockUI: NgBlockUI;
  
  perfis: string[];
  renderPagina: boolean = false;
  displayedColumns: string[] = ['empresa', 'administrador', 'data','simples', 'status'];
  dataSource = new MatTableDataSource<Ocorrencia>();

  ocorrencias: Ocorrencia[] = [];

  constructor(
    private ocorrenciaService: OcorrenciaService,
    private securityService: SecurityService,
    private router: Router,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.buscarOcorrencias();
  }

  hiddenSection():boolean {
    return this.renderPagina;
  }

  buscarOcorrencias() {
    this.blockUI.start("Buscando denúncias...");
    this.ocorrenciaService.buscarOcorrencias("abertas").subscribe(data => {
      this.dataSource = new MatTableDataSource<Ocorrencia>(data);
      this.dataSource.paginator = this.paginator;
      this.blockUI.stop();
    }, error => {
      let status = error.status;
      if (status === 0 || status === 500 || status === 501 || status === 502 || status === 503 || status === 504 || status === 505) {        
        this.toastr.warning("Serviço temporariamente indisponível.");
      } else {
        this.toastr.warning("Erro ao realizar busca.");
      }
      this.blockUI.stop();
    });

  }

  voltar() {
    this.router.navigate(['painel']);
  }

  acompanhamento(ocorrencia: Ocorrencia) {
    if (ocorrencia != null && ocorrencia != undefined) {
      // armazenar as informações da ocorrência selecionada no local storage
      window.localStorage.setItem('detalhe_ocorrencia', JSON.stringify(ocorrencia));
      // redirecionar para a tela de acompanhamento de ocorrência
      this.router.navigate(['painel/acompanhamento']);
    }
  }
  
}