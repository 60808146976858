import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GenericService } from '../core/generic.service';
import { Observable } from 'rxjs';
import { Usuario } from '../model/usuario';
import { Arquivo } from '../model/arquivo';

@Injectable({
  providedIn: 'root'
})
export class ArquivoService extends GenericService {

  private relativePath: string = 'api/files';

  constructor(http: HttpClient) {
    super(http);
  }

  upload(arquivo: Arquivo, arquivoBytes: File) : Observable<any> {
    const formData: FormData = new FormData();
    formData.append('arquivo', JSON.stringify(arquivo));
    formData.append('arquivoBytes', arquivoBytes, arquivoBytes.name);
    return this.http.post(this.url + this.relativePath + "/upload", formData);
  }

   // todo recebe um array de bytes e transforma no arquivo de acordo 
   // com o tipo de formato do arquivo
  download(idArquivo: number) : Observable<any> {
    return this.http.get(this.url + this.relativePath + "/download/" + idArquivo, { responseType: 'blob' });
  }

  atualizarStatus(arquivo: Arquivo) : Observable<any> {
    return this.http.put(this.url + this.relativePath + "/update/status", arquivo);
  }

  buscarPorOcorrencia(idOcorrencia: number): Observable<any> {
    return this.http.get(this.url + this.relativePath + "/find/occurrences/" + idOcorrencia);
  }

}