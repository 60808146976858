import { ProcuradoriaComentario } from './procuradoria-comentario';
import { ProcuradoriaArquivo } from './procuradoria-arquivo';

export class ProcuradoriaEtapa {
    id: number;
    idEmpresa: number;
    dataInicio: Date;
    dataConclusao: Date;
    etapa: string;
    descricao: string;
    orgao: string;
    status: boolean;
    posicao: number;
    comentarios: ProcuradoriaComentario[];
    arquivos: ProcuradoriaArquivo[];
}