import { Component, OnInit, ViewChild } from '@angular/core';
import { PERFIS, SecurityService } from 'src/app/security/_services/security.service';
import { MatTableDataSource, MatPaginator } from '@angular/material';
import { EmpresaProcuradoria } from 'src/app/model/empresa-procuradoria';
import { EmpresaService } from 'src/app/service/empresa.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-painel',
  templateUrl: './painel.component.html',
  styleUrls: ['./painel.component.css']
})
export class PainelComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @BlockUI() blockUI: NgBlockUI;
  
  perfis: string[];
  renderPagina: boolean = false;
  displayedColumns: string[] = ['empresa', 'administrador', 'simples', 'acoes'];
  dataSource = new MatTableDataSource<EmpresaProcuradoria>();

  constructor(
    private empresaService: EmpresaService,
    private securityService: SecurityService,
    private router: Router,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.perfis = JSON.parse(window.localStorage.getItem(PERFIS));
    this.verificarPerfis();
  }

  verificarPerfis() {
    if (this.securityService.isGerente(this.perfis) && this.securityService.isProcuradoria(this.perfis)) {
      this.renderPagina = true;
    } else {
      this.renderPagina = false;
    }
  }

  hiddenSection():boolean {
    return this.renderPagina;
  }

  abertas() {
    this.router.navigate(['painel/denuncias']);
  }

  finalizadas() {
    this.router.navigate(['painel/finalizadas']);
  }

  estatisticas() {
    console.log("estatisticas");
  }  

}
