import { Component, OnInit } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-buscar-ocorrencia',
  templateUrl: './buscar-ocorrencia.component.html',
  styleUrls: ['./buscar-ocorrencia.component.css']
})
export class BuscarOcorrenciaComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;

  // controladores do fluxo do form
  telaBusca: boolean = false;
  telaDetalhe: boolean = true;

  buscarFormGroup: FormGroup;

  codigo: string;

  constructor(
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.buscarFormGroup = this.formBuilder.group({
      codigo: ['', Validators.required]
    });
  }

  step1() : boolean {
    return this.telaBusca;
  }

  step2() : boolean {
    return this.telaDetalhe;
  }

  buscarOcorrencia() { // step1
    this.codigo = this.buscarFormGroup.get('codigo').value;
    if (this.codigo != null && this.codigo != "" && this.codigo != undefined) {
      this.telaBusca = true;
      this.telaDetalhe = false;
    }
  }

  voltar() {
    this.telaBusca = false;
    this.telaDetalhe = true;
  }

}