import { Injectable } from '@angular/core';
import { GenericService } from '../core/generic.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService extends GenericService {

  private relativePath = 'api/cnae';

  constructor(http: HttpClient) {
    super(http);
  }


  postFile(fileToUpload: File) : Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    return this.http.post(this.url + this.relativePath + "/files", formData);
  }

  // postFile2(fileToUpload: File): Observable<boolean> {
  //   const endpoint = 'your-destination-url';
  //   const formData: FormData = new FormData();
  //   formData.append('fileKey', fileToUpload, fileToUpload.name);
  //   return this.httpClient
  //     .post(endpoint, formData, { headers: yourHeadersConfig })
  //     .map(() => { return true; });
  //     .catch((e) => this.handleError(e));
  // }

}
