import { Injectable } from '@angular/core';
import { GenericService } from '../core/generic.service';
import { HttpClient } from '@angular/common/http';
import { ProcuradoriaArquivo } from '../model/procuradoria-arquivo';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProcuradoriaArquivoService extends GenericService {

  private relativePath = 'api/procuradoria/arquivo';

  constructor(http: HttpClient) {
    super(http);
  }

  upload(procuradoriaArquivo: ProcuradoriaArquivo, arquivo: File) : Observable<any> {
    const formData: FormData = new FormData();
    formData.append('procuradoriaArquivo', JSON.stringify(procuradoriaArquivo));
    formData.append('arquivo', arquivo, arquivo.name);
    return this.http.post(this.url + this.relativePath + "/upload", formData);
  }

  download(idArquivo: number) : Observable<any> { // todo recebe um array de bytes e transforma no arquivo de acordo com o tipo de formato do arquivo
    return this.http.get(this.url + this.relativePath + "/download/" + idArquivo, { responseType: 'blob' });
  }

  buscarArquivosPorEtapa(idEtapa: number): Observable<any> {
    return this.http.get(this.url + this.relativePath + "/find/etapa/" + idEtapa);
  }

  atualizarStatus(procuradoriaArquivo: ProcuradoriaArquivo) : Observable<any> {
    return this.http.put(this.url + this.relativePath + "/update/status", procuradoriaArquivo);
  }

}