import { Injectable } from '@angular/core';
import { GenericService } from '../core/generic.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EmpresaProcuradoria } from '../model/empresa-procuradoria';

@Injectable({
  providedIn: 'root'
})
export class EmpresaService extends GenericService { // todo mudar nome para ProcuradoriaEmpresaService

  private relativePath: string = "api/empresa"

  constructor(http: HttpClient) { 
    super(http);
  }

  novaEmpresa(empresa: EmpresaProcuradoria) : Observable<any> {
    return this.http.post(this.url + this.relativePath + "/add", empresa);
  }

  atualizarEmpresa(empresa: EmpresaProcuradoria) : Observable<any> {
    return this.http.put(this.url + this.relativePath + "/update", empresa);
  }

  buscarEmpresas() : Observable<any>  {
    return this.http.get(this.url + this.relativePath + "/all");
  }

  buscarEmpresaPorIdUsuario(idUsuarioSeg: number) : Observable<any> {
    return this.http.get(this.url + this.relativePath + "/find/idUsuarioSeg/" + idUsuarioSeg);
  }

}
