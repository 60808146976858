import { Component, OnInit } from '@angular/core';
import { FileUploadService } from 'src/app/service/file-upload.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Listas } from 'src/app/model/listas';
import { UsuarioService } from 'src/app/service/usuario.service';
import { Usuario } from 'src/app/model/usuario';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { MaskUtil } from 'src/app/utils/mask-util';
import { Ocorrencia } from 'src/app/model/ocorrencia';
import { OcorrenciaService } from 'src/app/service/ocorrencia.service';
import { ArquivoService } from 'src/app/service/arquivo.service';
import { Arquivo } from 'src/app/model/arquivo';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;

  // controladores do fluxo do form
  assunto: boolean = false;
  identificacao: boolean = true;
  dadosIdentificado: boolean = true;
  dadosAnonimo: boolean = true;
  dadosOcorrido: boolean = true;
  sucessoEnvio: boolean = true;

  // variaveis ngModel
  anonimo: any;
  identificacaoDenunciado: any;
  recorrenciaEvento: any;


  fileToUpload: File = null;

  listas = new Listas();  

  homeFormGroup: FormGroup;

  ocorrencia: Ocorrencia = new Ocorrencia();

  constructor(
    private fileUploadService: FileUploadService,
    private arquivoService: ArquivoService,
    private formBuilder: FormBuilder,
    private usuarioService: UsuarioService,
    private ocorrenciaService: OcorrenciaService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.initForm();
    console.log('anonimo', this.anonimo);
  }

  initForm() {
    this.homeFormGroup = this.formBuilder.group({
      assunto: ['', Validators.required],
      
      nomeDenunciante: ['', Validators.required],
      emailDenunciante: ['', Validators.required, Validators.email],
      cpfDenunciante: ['', Validators.required],
      telefoneDenunciante: ['', Validators.required],
      relacaoEmpresaDenunciante: ['', Validators.required],
      cargoAreaDenunciante: ['', Validators.required],

      emailAnonimo: ['', Validators.email],

      relacaoEmpresaDenunciado: ['', Validators.required],
      dataOcorrencia: [''],
      tempoOcorrencia: [''],
      codigoRelatoAnterior: [''],
      origemOcorrencia: [''],
      valorOcorrencia: [''],
      sugestaoSolucao: [''],
      categoriaRelato: [''],
      descricaoEvidencias: [''],
      arquivoEvidencias: ['']
    });
  }

  step1() : boolean {
    return this.assunto;
  }

  step2() : boolean {
    return this.identificacao;
  }

  step3() : boolean {
    return this.dadosIdentificado;
  }

  step4() : boolean {
    return this.dadosAnonimo;
  }

  step5() : boolean {
    return this.dadosOcorrido;
  }

  step6() : boolean {
    return this.sucessoEnvio;
  }

  escolherIdentificacao() { // step1
    let assunto = this.homeFormGroup.get('assunto').value;
    if (assunto != null && assunto != "" && assunto != undefined) {
      this.assunto = true;
      this.identificacao = false;
    }
    console.log(assunto);
  }

  voltarAssunto() {
    this.assunto = false;
    this.identificacao = true;
  }

  dadosPessoais() { // step2
    if (this.anonimo == 1) {
      this.identificacao = true;
      this.dadosIdentificado = false;
    } else {
      this.identificacao = true;
      this.dadosAnonimo = false;
    }
  }

  voltarIdentificacao() {
    this.identificacao = false;
    this.dadosIdentificado = true;
    this.dadosAnonimo = true;
  }

  ocorrido() {
    this.dadosIdentificado = true;
    this.dadosAnonimo = true;
    this.dadosOcorrido = false;
  }

  voltarDadosPessoais() {
    if (this.anonimo == 1) {
      this.dadosIdentificado = false;
    } else {
      this.dadosAnonimo = false;
    }
    this.dadosOcorrido = true;
  }

  enviar() {
    // if (this.homeFormGroup.invalid) {
    //   return;
    // }
    
    this.ocorrencia.assunto = this.homeFormGroup.get('assunto').value;;
    this.ocorrencia.anonimo = this.anonimo;
    this.ocorrencia.emailAnonimo = this.homeFormGroup.get('emailAnonimo').value;
    this.ocorrencia.nomeDenunciante = this.homeFormGroup.get('nomeDenunciante').value;
    this.ocorrencia.emailDenunciante = this.homeFormGroup.get('emailDenunciante').value;
    this.ocorrencia.cpfDenunciante = this.homeFormGroup.get('cpfDenunciante').value;
    this.ocorrencia.telefoneDenunciante = this.homeFormGroup.get('telefoneDenunciante').value;
    this.ocorrencia.relacaoEmpresaDenunciante = this.homeFormGroup.get('relacaoEmpresaDenunciante').value;
    this.ocorrencia.cargoAreaDenunciante = this.homeFormGroup.get('cargoAreaDenunciante').value;
    this.ocorrencia.relacaoEmpresaDenunciado = this.homeFormGroup.get('relacaoEmpresaDenunciado').value;
    this.ocorrencia.identificacaoDenunciado = this.identificacaoDenunciado;
    this.ocorrencia.dataOcorrencia = this.homeFormGroup.get('dataOcorrencia').value;
    this.ocorrencia.recorrenciaEvento = this.recorrenciaEvento;
    this.ocorrencia.tempoOcorrencia = this.homeFormGroup.get('tempoOcorrencia').value;
    this.ocorrencia.codigoRelatoAnterior = this.homeFormGroup.get('codigoRelatoAnterior').value
    this.ocorrencia.origemOcorrencia = this.homeFormGroup.get('origemOcorrencia').value;
    this.ocorrencia.valorOcorrencia = this.homeFormGroup.get('valorOcorrencia').value;
    this.ocorrencia.sugestaoSolucao = this.homeFormGroup.get('sugestaoSolucao').value;
    this.ocorrencia.categoriaRelato = this.homeFormGroup.get('categoriaRelato').value;
    this.ocorrencia.descricaoEvidencias = this.homeFormGroup.get('descricaoEvidencias').value;
    this.ocorrencia.arquivos = null;
    

    console.log("Ocorrencia", this.ocorrencia);
    // console.log('cpf', MaskUtil.cpfToMask(usuario.cpf));

    this.blockUI.start();
    this.ocorrenciaService.nova(this.ocorrencia).subscribe(result => {
      console.log(result);
      this.ocorrencia = result;
      this.sucessoEnvio = false;
      this.dadosOcorrido = true;

      if (this.fileToUpload) {
        let arquivo = new Arquivo();
        arquivo.ocorrencia = this.ocorrencia;

        this.arquivoService.upload(arquivo, this.fileToUpload).subscribe(data => {
          console.log("Upload bem sucedido!");
          console.log("file", data);
          this.blockUI.stop();
        }
        // , error => {
        //   let status = error.status;
        //   if (status === 0 || status === 500 || status === 501 || status === 502 || status === 503 || status === 504 || status === 505) {        
        //     this.toastr.warning("Serviço temporariamente indisponível.");
        //   } else {
        //     this.toastr.warning("Erro ao realizar upload de arquivo.");
        //   }
        //   this.blockUI.stop();
        // }
        );
      }

      this.toastr.success("Sua ocorrência foi cadastrada.");
      this.blockUI.stop();
    }, error => {
      let status = error.status;
      if (status === 400 || status === 401 || status === 403) {
        this.toastr.warning("Erro ao realizar cadastro.");
      } else if (status === 0 || status === 500 || status === 501 || status === 502 || status === 503 || status === 504 || status === 505) {
        this.toastr.warning("Serviço temporariamente indisponível.");
      } else {
        this.toastr.warning("Erro ao realizar cadastro.");
      }
      this.blockUI.stop();
    });
  
  }

  // TODO upload de arquivos
  inputArquivo(files: FileList) {
    this.fileToUpload = files.item(0);
  }

  // TODO envio de arquivos para backend
  uploadFileToActivity() {
    if (this.fileToUpload) {
      this.blockUI.start();
      this.fileUploadService.postFile(this.fileToUpload).subscribe(data => {
          console.log("Upload bem sucedido!");
          console.log("file", data);
          this.blockUI.stop();
        }, error => {
          let status = error.status;
          if (status === 0 || status === 500 || status === 501 || status === 502 || status === 503 || status === 504 || status === 505) {        
            this.toastr.warning("Serviço temporariamente indisponível.");
          } else {
            this.toastr.warning("Erro ao realizar upload de arquivo.");
          }
          this.blockUI.stop();
        });
    } else {
      console.log("Não há arquivo a ser enviado.");
    }
  }

}
