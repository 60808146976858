import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { AuthenticationGuard } from './security/_guards/authentication.guard';
import { HomeComponent } from './pages/home/home.component';
import { CadastroComponent } from './pages/cadastro/cadastro.component';
import { PainelComponent } from './pages/painel/painel.component';
import { AbertasComponent } from './pages/painel/ocorrencias/abertas/abertas.component';
import { AcompanhamentoEmpresaComponent } from './pages/painel/ocorrencias/acompanhamento/acompanhamento.component';
import { BuscarOcorrenciaComponent } from './pages/acompanhamento/buscar/buscar-ocorrencia.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'cadastro', component: CadastroComponent },
  { path: 'painel', component: PainelComponent, canActivate: [AuthenticationGuard] },
  { path: 'painel/denuncias', component: AbertasComponent, canActivate: [AuthenticationGuard] },
  { path: 'painel/finalizadas', component: AbertasComponent, canActivate: [AuthenticationGuard] },
  { path: 'painel/constituicao', component: AbertasComponent, canActivate: [AuthenticationGuard] },
  { path: 'painel/acompanhamento', component: AcompanhamentoEmpresaComponent }, //, canActivate: [AuthenticationGuard] },
  { path: 'ocorrencia', component: BuscarOcorrenciaComponent },
  { path: '', component: HomeComponent },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
