import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GenericService } from '../core/generic.service';
import { Observable } from 'rxjs';
import { Usuario } from '../model/usuario';
import { Comentario } from '../model/comentario';

@Injectable({
  providedIn: 'root'
})
export class ComentarioService extends GenericService {

  private relativePath: string = 'api/comments';

  constructor(http: HttpClient) {
    super(http);
  }

  novo(comentario: Comentario) : Observable<any> {
    return this.http.post(this.url + this.relativePath + "/add", comentario);
  }

  buscarPorId(id: number) : Observable<any> {
    return this.http.get(this.url + this.relativePath + "/find/" + id);
  }

  buscarPorOcorrencia(id: number) : Observable<any> {
    return this.http.get(this.url + this.relativePath + "/find/occurrence/" + id);
  }

}
