import { Injectable } from '@angular/core';
import { GenericService } from '../core/generic.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CnaeService extends GenericService {

  private relativePath = 'api/cnae';

  constructor(http: HttpClient) {
    super(http);
  }

  buscarPorSecao(secao: string) : Observable<any> {
    return this.http.get(this.url + this.relativePath + "/find/secao/" + secao);
  }

}
