export class Listas {

  assuntos = [
    { id: null, value: "Selecione o assunto" },
    { id: 1, value: "Corrupção" },
    { id: 2, value: "Fraude ou Falsificação de Documento" },
    { id: 3, value: "Furto ou Roubo" },
    { id: 4, value: "Lavagem de Dinheiro" },
    { id: 5, value: "Uso indevido de imagem ou nome da empresa" },
    { id: 6, value: "Desvios Éticos e de Conduta" },
    { id: 7, value: "Assédio moral" },
    { id: 8, value: "Assédio sexual" },
    { id: 9, value: "Discriminaçção" },
    { id: 10, value: "Outras ilegalidades" }
  ];

  relacaoEmpresa = [
    { id: null, value: "Selecione a categoria" },
    { id: 1, value: "Colaborador(a)" },
    { id: 2, value: "Ex-colaborador(a)" },
    { id: 3, value: "Cliente"},
    { id: 4, value: "Terceirizado(a)" },
    { id: 5, value: "Fornecedor(a)" },
    { id: 6, value: "Outro" }
  ];

  categoriasDenunciado = [
    { id: null, value: "Selecione a categoria" },
    { id: 1, value: "Colaborador(a)(s)" },
    { id: 2, value: "Cliente(s)" },
    { id: 3, value: "Terceirizado(s)" }, 
    { id: 4, value: "Outro" }
  ];


  categoriasRelato = [
    { id: null, value: "Selecione a categoria" },
    { id: 1, value: "Suspeita do ocorrido" },
    { id: 2, value: "Presenciou o ocorrido" },
    { id: 3, value: "Tem provas do ocorrido" },
    { id: 4, value: "Outros" }
  ];

  simNao = [
    { id: 1, value: "Sim" },
    { id: 2, value: "Não" }
  ];

}