import { Injectable } from '@angular/core';
import { GenericService } from '../core/generic.service';
import { HttpClient } from '@angular/common/http';
import { ProcuradoriaComentario } from '../model/procuradoria-comentario';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProcuradoriaComentarioService extends GenericService {

  private relativePath = 'api/procuradoria/etapas/comentarios';

  constructor(http: HttpClient) {
    super(http);
  }

  enviarComentario(procuradoriaComentario: ProcuradoriaComentario) : Observable<any> {
    return this.http.post(this.url + this.relativePath + "/add", procuradoriaComentario);
  }

  buscarPorEtapa(idEtapa: number) : Observable<any> {
    return this.http.get(this.url + this.relativePath + "/find/etapa/" + idEtapa);
  }

  atualizar(procuradoriaComentario: ProcuradoriaComentario) : Observable<any> {
    return this.http.put(this.url + this.relativePath + "/update", procuradoriaComentario);
  }

  remover(idComentario: number) : Observable<any> {
    return this.http.delete(this.url + this.relativePath + "/remove/" + idComentario);
  }

}
