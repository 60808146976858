import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthenticationResult } from '../_models/AthenticationResult';
import { AccountCredentials } from '../_models/AccountCredentials';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { GenericService } from 'src/app/core/generic.service';
import * as jwt_decode from 'jwt-decode';
import { Router } from '@angular/router';
import { UsuarioService } from 'src/app/service/usuario.service';

export const TOKEN: string = 'jwt_token';
export const PERFIS: string = 'perfis';
export const EXP_DATE: string = 'exp_date';
export const USUARIO_SEG: string = 'usuario_seg';

@Injectable({
  providedIn: 'root'
})
export class SecurityService extends GenericService {
  
  private relativePath: string = 'auth/login';
  perfis: string[];
  redirecionar: string;

  constructor(http: HttpClient, private router: Router, private usuarioService: UsuarioService) { 
    super(http);
  }

  authenticate(email: string, senha: string) : Observable<AuthenticationResult> {
    let accountCredentials = new AccountCredentials(email, senha);

    return this.http.post(this.url + this.relativePath, accountCredentials, { observe: 'response' })
      .pipe(map(res => {
        let auth = res.headers.get('Authorization');
        let parts = auth.split(' ');

        if (parts.length === 2) {
          let scheme = parts[0];
          let jwt = parts[1];

          if (/^Bearer$/i.test(scheme) && jwt) {
            window.localStorage.setItem(TOKEN, jwt);
            return new AuthenticationResult(true, null);
          }
        }
      }));
  }

  saveSessionPerfis(token) {
    let perfis: string[] = [];
    let payload = jwt_decode(token);
    let roles = payload.roles;
    let expirationDate = payload.exp; // milliseconds
    
    // console.log("payload", payload);
    // console.log('exp', expirationDate);
    // console.log(new Date(expiration * 1000));
    // console.log(token);

    roles.forEach(role => {
      perfis.push(role.authority);
    });
    
    window.localStorage.removeItem(PERFIS);
    window.localStorage.removeItem(EXP_DATE);
    window.localStorage.setItem(PERFIS, JSON.stringify(perfis));
    window.localStorage.setItem(EXP_DATE, JSON.stringify(expirationDate));
  }

  clearSessionData() {
    window.localStorage.removeItem(TOKEN);
    window.localStorage.removeItem(PERFIS);
    window.localStorage.removeItem(EXP_DATE);
    window.localStorage.removeItem(USUARIO_SEG);
    window.localStorage.clear();
  }

  saveUserData(email: string) {
    this.usuarioService.buscarPorEmail(email).subscribe(usuario => {
      window.localStorage.setItem(USUARIO_SEG, JSON.stringify(usuario));
      console.log(window.localStorage.getItem(USUARIO_SEG));
    });
  }

  authenticationPresent() : boolean {
    return window.localStorage.getItem(TOKEN) !== null && window.localStorage.getItem(PERFIS).length !== 0 && !this.authenticationExpired();
  }

  getPerfis() { // todo JSON.parse(window.localStorage.getItem(PERFIS));
    return window.localStorage.getItem(PERFIS);
  }

  authenticationExpired() : boolean {
    let dateNow = new Date();
    let expirationDate: number;

    if (window.localStorage.getItem(EXP_DATE)) {
      expirationDate = Number.parseInt(window.localStorage.getItem(EXP_DATE)) * 1000;

      if (expirationDate > dateNow.getTime()) {
        return false;
      }
    }

    return true;
  }

  redirect() {
    this.perfis = JSON.parse(window.localStorage.getItem(PERFIS));
    if (this.perfis != null && this.perfis != undefined) {
      this.redirecionar = this.verificarPerfis();
    }

    this.router.navigate([this.redirecionar]);
    
    // else {
    //   this.clearSessionData();
    //   this.router.navigate(['login']);
    // }
    
    //this.router.navigate(['']);
  }

  verificarPerfis() : string { // todas as outras combinações de perfis
    let urlRedirecionamnto = ''; 
    if (this.isGerente(this.perfis)) {
      if (this.isProcuradoria(this.perfis)) {
        urlRedirecionamnto = 'ad/procuradoria';
      } else if (this.isFiscal(this.perfis)) {
        urlRedirecionamnto = 'ad/fiscal';
      } else if (this.isContabil(this.perfis)) {
        urlRedirecionamnto = 'ad/contabil';
      } else if (this.isFolha(this.perfis)) {
        urlRedirecionamnto = 'ad/folha';
      } else if (this.isAdmFin(this.perfis)) {
        urlRedirecionamnto = 'ad/admfin';
      }
    } else {
      urlRedirecionamnto = '';
    }

    return urlRedirecionamnto;
  }

  isAdvice(perfis: string[]) : boolean {
    var retorno = false;
    perfis.forEach(element => {
        if (element == "ROLE_ADVICE") {
            retorno = true;
        }
    });
    return retorno;
  }

  isGerente(perfis: string[]) : boolean {
    var retorno = false;
    perfis.forEach(element => {
        if (element == "ROLE_GERENTE") {
            retorno = true;
        }
    });
    return retorno;
  }

  isProcuradoria(perfis: string[]) : boolean {
    var retorno = false;
    perfis.forEach(element => {
        if (element == "ROLE_PROCURADORIA") {
            retorno = true;
        }
    });
    return retorno;
  }

  isFiscal(perfis: string[]) : boolean {
    var retorno = false;
    perfis.forEach(element => {
        if (element == "ROLE_FISCAL") {
            retorno = true;
        }
    });
    return retorno;
  }

  isContabil(perfis: string[]) : boolean {
    var retorno = false;
    perfis.forEach(element => {
        if (element == "ROLE_CONTABIL") {
            retorno = true;
        }
    });
    return retorno;
  }

  isFolha(perfis: string[]) : boolean { // todo departamento pessoal
    var retorno = false;
    perfis.forEach(element => {
        if (element == "ROLE_FOLHA") {
            retorno = true;
        }
    });
    return retorno;
  }

  isAdministrador(perfis: string[]) : boolean {
    var retorno = false;
    perfis.forEach(element => {
        if (element == "ROLE_ADMINISTRADOR") {
            retorno = true;
        }
    });
    return retorno;
  }

  isColaborador(perfis: string[]) : boolean {
    var retorno = false;
    perfis.forEach(element => {
        if (element == "ROLE_COLABORADOR") {
            retorno = true;
        }
    });
    return retorno;
  }

  isComercial(perfis: string[]) : boolean {
    var retorno = false;
    perfis.forEach(element => {
        if (element == "ROLE_COMERCIAL") {
            retorno = true;
        }
    });
    return retorno;
  }

  isAdmFin(perfis: string[]) : boolean {
    var retorno = false;
    perfis.forEach(element => {
        if (element == "ROLE_ADMFIN") {
            retorno = true;
        }
    });
    return retorno;
  }

}
