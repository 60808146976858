import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SecurityService } from '../_services/security.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {

  bool1: boolean = null;
  bool2: boolean = null;

  constructor(
    private router: Router,
    private securityService: SecurityService,
    private toastr: ToastrService
  ) { }
  
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if(!this.securityService.authenticationPresent()) {
      // this.toastr.warning('Nenhuma autenticação presente. Entre com suas credenciais.');
      // console.log('Nenhuma Autenticação Presente, redirecionando para Login');
      this.securityService.clearSessionData();
      this.router.navigate(['login']);
      return false;
    }
    return true;
  }
  
}
