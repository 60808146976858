import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { SecurityService, TOKEN, PERFIS, EXP_DATE } from 'src/app/security/_services/security.service';
import { UsuarioService } from 'src/app/service/usuario.service';
import * as jwt_decode from 'jwt-decode';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;

  title = 'Advice Web';
  loginFormGroup: FormGroup;
  hide = true;

  constructor(
    private formBuilder: FormBuilder,
    private securityService: SecurityService,
    private router: Router,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    if (this.securityService.authenticationPresent()) {
      this.router.navigate(['']);
    }
    this.initLoginForm();
    // console.log("token", window.localStorage.getItem(TOKEN));
    // console.log("perfis", window.localStorage.getItem(PERFIS));
    // console.log("exp", new Date(Number.parseInt(window.localStorage.getItem(EXP_DATE)) * 1000));
  }

  initLoginForm() {
    this.loginFormGroup = this.formBuilder.group({
      email: ['', Validators.required],
      senha: ['', Validators.required]
    });
  }

  login() {
    if (this.loginFormGroup.invalid) {
      return;
    }

    const email = this.loginFormGroup.get('email').value;
    const senha = this.loginFormGroup.get('senha').value;
    
    this.blockUI.start();
    this.securityService.authenticate(email, senha).subscribe(result => {
      let token = window.localStorage.getItem(TOKEN);

      if (result.ok && token) {
        this.securityService.saveSessionPerfis(token);
        this.securityService.saveUserData(email);
        // this.securityService.redirect();
        this.router.navigate(['painel']);
      } else {
        console.log(result.message);
      }
      this.blockUI.stop();
    }, error => {
      let status = error.status;
      if (status === 401 || status === 403) {
        console.log("E-mail ou senha inválidos.");
        this.toastr.warning("E-mail ou senha inválidos.");
      } else if (status === 0 || status === 500 || status === 501 || status === 502 || status === 503 || status === 504 || status === 505) {
        console.log("Serviço temporariamente indisponível.");
        this.toastr.warning("Serviço temporariamente indisponível.");
      } else {
        console.log("Erro ao realizar login.");
        this.toastr.warning("Erro ao realizar login.");
      }
      this.blockUI.stop();
    });
  }

}
